import React from 'react'
import logo from '../../assets/images/logo-white.png';

const HomePage = () => {
    return (
        <div id='home'>
            <img className='homeLogo' src={logo} alt="Logo" />
        </div>
    )
}



export default HomePage
